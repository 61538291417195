import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const ModalTOS = ({ showTOS }) => {
  const [show, setShow] = useState(showTOS);

  useEffect(() => {
    setShow(showTOS);
  }, [showTOS]);

  const handleClose = () => setShow(false);

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" centered>
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body>
          <h2 className="text-center mt-4">Terms of Service</h2>
          <p className="text-center my-1">
            Your use of the Crypto Unicorns platform and any purchase you make
            of Crypto Unicorns digital assets are governed by our Terms of
            Service, available{" "}
            <a
              className="btn-text bold"
              href="https://www.cryptounicorns.fun/tos.html"
              target="_blank"
              rel="noreferrer">
              here
            </a>
            .{" "}
          </p>
          <p className="text-center mb-1 mt-3">
            Please click below to acknowledge that you have carefully reviewed
            and agree to these Terms. If you have any questions about the Terms,
            you can send an email to{" "}
            <a className="btn-text" href="mailto:legal@laguna.games">
              legal@laguna.games
            </a>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="mb-4" variant="primary" onClick={handleClose}>
            I Agree
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalTOS;
