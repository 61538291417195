import { useState, useEffect } from "react";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import egg_soldout from "../img/CU_soldout.png";

const ModalSoldout = ({ soldout }) => {
  const [show, setShow] = useState(soldout);

  useEffect(() => {
    setShow(soldout);
  }, [soldout]);

  const handleClose = () => setShow(false);

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" centered>
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body>
          <h2 className="text-center">Sold Out</h2>
          <img className="img-egg error py-3" src={egg_soldout} alt="" />
          <p className="text-center my-1">
            {"Sorry, we're all sold out of eggs"}
          </p>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button className="mb-4" variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default ModalSoldout;
