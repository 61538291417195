import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import egg_success from "../img/CU_success.jpg";

const ModalSuccess = ({ success, transactionHash }) => {
  const [show, setShow] = useState(success);

  useEffect(() => {
    setShow(success);
  }, [success]);

  const handleClose = () => setShow(false);

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2 className="text-center">Success!</h2>
          <img className="img-egg py-3" src={egg_success} alt="" />
          <p className="text-center my-1">Your eggs have been purchased!</p>
        </Modal.Body>
        <Modal.Footer>
          <a
            href={`https://polygonscan.com/tx/${transactionHash}`}
            target="_blank"
            rel="noreferrer">
            <Button className="mb-4" variant="primary">
              View Transaction
            </Button>
          </a>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalSuccess;
