import { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Card,
  InputGroup,
  FormControl,
  Container,
  Row,
  Col,
  Spinner,
  Alert,
} from "react-bootstrap";
import Onboard from "bnc-onboard";
import Web3 from "web3";

import ModalSuccess from "./components/ModalSuccess";
import ModalError from "./components/ModalError";
import ModalPending from "./components/ModalPending";
import ModalTOS from "./components/ModalTOS";
import ModalSoldout from "./components/ModalSoldout";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";

import logo from "./img/logo.png";
import bg_tile from "./img/bg_tile.jpg";
import egg_1 from "./img/CU_eggs.jpg";

import CU_ERC721_ABI from "./CU_ERC721_ABI.json";
import WETH_ABI from "./WETH_ABI.json";

const NetworkConfiguration = {
  mainnet: {
    networkId: 137,
    CUContractAddress: "0xdC0479CC5BbA033B3e7De9F178607150B3AbCe1f",
    WETHContractAddress: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
  },
  shadow: {
    networkId: 137,
    CUContractAddress: "0x22E13Df6a55c1AEDa3912AC41719df721354d556",
    WETHContractAddress: "0x699fBE944DEd6811d407e928ab91896712248f5a",
  },
  mumbai: {
    networkId: 80001,
    CUContractAddress: "0x0780Cd8f9136653684cA5d6fb7A2e2e9ee1eE2B9",
    WETHContractAddress: "0x17C52D41891b1c8a31F8A145bDd11f722Fd5db03",
  },
  ganache: {
    networkId: 5777,
    CUContractAddress: "0x8E141ee7EFFFB029E9C1c435efa0553C011Fd3a2",
    WETHContractAddress: "",
  },
};

function App() {
  const POLYGON_NETWORK = process.env.REACT_APP_POLYGON_NETWORK;

  const CONNECT_YOUR_WALLET = "Connect your wallet";

  const netconfig = NetworkConfiguration[POLYGON_NETWORK];
  if (!netconfig) {
    console.error(
      `Unsupported polygon network: ${POLYGON_NETWORK}. Valid choices: mainnet, mumbai, ganache.`
    );
  }

  // Possible states for blockchain workflow:
  // 1. WETH token has not been approved, no genesis eggs have been claimed. We DO NOT know how many eggs the wallet is allowed to claim. We DO NOT KNOW how much WETH we are approved to transfer.
  // 2. WETH token has not been approved, no genesis eggs have been claimed. We DO know how many eggs the wallet is allowed to claim. We DO NOT KNOW how much WETH we are approved to transfer.
  // 3. WETH token has not been approved, no genesis eggs have been claimed. We DO NOT know how many eggs the wallet is allowed to claim. We DO KNOW how much WETH we are approved to transfer.
  // 4. WETH token has not been approved, no genesis eggs have been claimed. We DO know how many eggs the wallet is allowed to claim. We DO KNOW how much WETH we are approved to transfer.
  // 5. WETH token HAS been sufficiently approved, no genesis eggs have been claimed. We DO know how many eggs the wallet is allowed to claim. We DO KNOW how much WETH we are approved to transfer.
  // 6. WETH token HAS been sufficiently approved, genesis eggs HAVE been claimed. We DO know how many eggs the wallet is allowed to claim. We DO KNOW how much WETH we are approved to transfer.

  // Need to know:
  // 1. WETH approval quantity.
  // 2. Remaining genesis eggs that user can claim.
  // 3. Status of WETH approval if initiated.
  // 4. Status of Genesis Egg claims if initiated.

  // TODO(Matthew): WETHAllowance is the amount of WETH we are allowed to claim. GenesisEggAllowance is the
  // number of mints the user can make. We should use this to validate user input and display more helpful
  // information to them on the frontend.
  const [WETHAllowance, setWETHAllowance] = useState(null);
  const [GenesisEggAllowance, setGenesisEggAllowance] = useState(null);
  // WETHApprovalStatus will be a transaction receipt with WETHApprovalStatus.status === true if the approval transaction succeeded.
  // GenesisEggClaimStatus will be a transaction receipt for the mint transaction on the Crypto Unicorns token. Same structure as WETHApprovalStatus.
  // const [WETHApprovalStatus, setWETHApprovalStatus] = useState(null);
  // const [GenesisEggClaimStatus, setGenesisEggClaimStatus] = useState(null);

  const [show, setShow] = useState(false);
  const [showTOS, setShowTOS] = useState(false);
  const [soldout, setSoldout] = useState(false);
  const [quantity, setQuantity] = useState("");
  const [pending, setPending] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [preApprovedMsg, setPreApprovedMsg] = useState(null);
  const [errorMessage, setErrorMessage] = useState(
    "Something has gone wrong, please try again."
  );
  const [approving, setApproving] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [walletStatus, setWalletStatus] = useState(CONNECT_YOUR_WALLET);

  const [web3, setWeb3] = useState(null);
  const [wallet, setWallet] = useState({});
  const [onboard, setOnboard] = useState(null);
  const [CUContract, setCUContract] = useState(null);
  const [WETHContract, setWETHContract] = useState(null);
  const [transactionHash, setTransactionHash] = useState(null);
  const [mintQuantityMsg, setMintQuantityMsg] = useState(null);

  useEffect(() => {
    setShow(!error);
  }, [error]);

  useEffect(() => {
    setShow(false);
    setApproving(false);
    setConfirming(false);
    setConfirmed(false);
    setSuccess(false);
    setError(false);
    setPending(false);
    setShowTOS(true);
    setSoldout(true);

    const onboard = Onboard({
      dappId: "2992ce4f-f0e4-4175-9df5-cc246c7ed5aa",
      networkId: netconfig.networkId,
      subscriptions: {
        wallet: (wallet) => {
          let web3 = new Web3(wallet.provider);
          console.log(`${wallet.name} is now connected!`);
          setWeb3(web3);
          setWallet(wallet);

          // These are being defined here because we need the wallet.provider to instantiate the
          // Web3 client.
          let _WETHContract = new web3.eth.Contract(
            WETH_ABI,
            netconfig.WETHContractAddress
          );
          setWETHContract(_WETHContract);

          let _CUContract = new web3.eth.Contract(
            CU_ERC721_ABI,
            netconfig.CUContractAddress
          );
          setCUContract(_CUContract);
        },
      },
    });

    setOnboard(onboard);
  }, [
    netconfig.CUContractAddress,
    netconfig.WETHContractAddress,
    netconfig.networkId,
  ]);

  useEffect(() => {
    if (
      !WETHContract ||
      !netconfig?.CUContractAddress ||
      !wallet?.provider?.selectedAddress
    ) {
      return;
    }

    WETHContract.methods
      .allowance(wallet.provider.selectedAddress, netconfig.CUContractAddress)
      .call()
      .then((res) => {
        setWETHAllowance(res);
        // console.log(WETHAllowance);
      });

    // Check if pre-approved and skip Approval step
    let _WETHApproved = WETHAllowance / 1e18;
    if (_WETHApproved >= 0.1) {
      // Cap to 0.3 if they've pre-approved more
      if (_WETHApproved > 0.3) {
        _WETHApproved = 0.3;
      }

      setApproving(true);
      setConfirmed(true);
      setQuantity(_WETHApproved * 10);
      let _eggTense = _WETHApproved === 0.1 ? "egg" : "eggs";
      setPreApprovedMsg(
        `You have pre-approved to mint ${
          _WETHApproved * 10
        } ${_eggTense}. The max number of eggs you can mint is 3.`
      );
    }
  }, [
    WETHAllowance,
    WETHContract,
    netconfig?.CUContractAddress,
    wallet?.provider?.selectedAddress,
  ]);

  useEffect(() => {
    if (!CUContract || !wallet?.provider?.selectedAddress) {
      return;
    }

    CUContract.methods
      .getMintAllowance(wallet.provider.selectedAddress)
      .call()
      .then((res) => {
        setGenesisEggAllowance(res);
        // console.log(GenesisEggAllowance);
      });
  }, [CUContract, GenesisEggAllowance, wallet?.provider?.selectedAddress]);

  useEffect(() => {
    if (!wallet.provider) {
      setWalletStatus(CONNECT_YOUR_WALLET);
    } else {
      // Shim to fix new Metamask logins
      setInterval(() => {
        setWalletStatus(
          wallet.provider.selectedAddress
            ? `Wallet Connected: ${wallet.provider.selectedAddress.slice(
                0,
                6
              )}...${wallet.provider.selectedAddress.slice(-4)}`
            : CONNECT_YOUR_WALLET
        );
      }, 1000);
    }
  }, [wallet?.provider, wallet?.provider?.selectedAddress]);

  async function login(props) {
    await onboard.walletSelect();
    await onboard.walletCheck();

    if (props.showModal) {
      setShow(true);
    }
  }

  const handleClose = () => setShow(false);

  const handleShow = () => {
    // Reset values
    setShow(true);
    // setApproving(false);
    // setConfirming(false);
    // setConfirmed(false);
    setSuccess(false);
    setError(false);
    setPending(false);
  };

  const handleKeyPress = (event) => {
    // Only allow numbers 1 through 3
    if (!/[1-3]/.test(event.key)) {
      event.preventDefault();
    } else {
      // Reset state to reapprove
      setApproving(false);
      setConfirming(false);
      setConfirmed(false);
      setPreApprovedMsg(null);
    }
  };

  const handleChange = (event) => {
    setQuantity(event.target.value);
  };

  const setMaxQuantity = () => {
    setQuantity(3);
    // setQuantity(GenesisEggAllowance || 3);

    // Reset state to reapprove
    setApproving(false);
    setConfirming(false);
    setConfirmed(false);
    setPreApprovedMsg(null);
  };

  const handleApprove = async () => {
    // Web3 Wallet Approval here
    setMintQuantityMsg(null);

    //Check if user is whitelisted to mint
    // if (Number(GenesisEggAllowance) < Number(quantity)) {
    //   //User either not whitelisted or he minted all his eggs
    //   if (Number(GenesisEggAllowance) === 0 || GenesisEggAllowance === null) {
    //     // window.alert("You either not whitelisted or minted all your eggs!");
    //     setMintQuantityMsg(
    //       "You have minted the maximum already, or your current wallet is not whitelisted"
    //     );
    //   } else {
    //     // window.alert(`You can mint max: ${GenesisEggAllowance} eggs.`);
    //     setMintQuantityMsg(`You can mint max: ${GenesisEggAllowance} eggs.`);
    //   }
    //   return;
    // }

    WETHContract.methods
      .approve(
        netconfig.CUContractAddress,
        // TODO(Matthew): This is the amount they will be approving. Display this to user on frontend.
        ((quantity * 0.1).toFixed(1) * Web3.utils.toBN(1e18)).toString()
      )
      .send({
        from: wallet.provider.selectedAddress,
      })
      .on("transactionHash", function () {
        setApproving(true);
      })
      .on("receipt", function (receipt) {
        // setWETHApprovalStatus(receipt);
        // Receipt structure: https://web3js.readthedocs.io/en/v1.2.11/web3-eth.html#eth-gettransactionreceipt-return
        if (!receipt.status) {
          setErrorMessage("Transaction was reverted on the blockchain.");
          setError(true);
        } else {
          setConfirmed(true);
        }
      })
      .on("error", function (err) {
        console.log(err);
        setErrorMessage(
          "There was an error submitting your transaction. Please report this to us on Discord: https://discord.gg/xSAAjcEWRU"
        );
        setError(true);
      });
  };

  const handleConfirm = () => {
    // setPending(true);

    CUContract.methods
      .claimGenesisTokens(wallet.provider.selectedAddress, quantity)
      .send({
        from: wallet.provider.selectedAddress,
      })
      .on("transactionHash", function (hash) {
        setShow(false);
        setPending(true);
        setConfirming(true);
        setTransactionHash(hash);
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
        // setGenesisEggClaimStatus(receipt);
        setPending(false);

        if (!receipt.status) {
          setErrorMessage("Transaction was reverted on the blockchain.");
          setError(true);
        } else {
          setSuccess(true);
          setShow(false);
        }
      })
      .on("error", function (err) {
        console.log(err);
        setPending(false);
        setError(true);
      });
  };

  return (
    <>
      <div
        className="App"
        id="root"
        style={{ backgroundImage: `url(${bg_tile})` }}>
        <a
          href="https://www.cryptounicorns.fun"
          target="_blank"
          rel="noreferrer">
          <img className="logo" src={logo} alt="Crypto Unicorns" />
        </a>
        <Button
          className="btn-wallet"
          variant="primary"
          onClick={() => login({ showModal: false })}>
          {walletStatus}
        </Button>
        <Card>
          <Card.Body>
            <h2>Mint a Genesis Egg</h2>
            <img className="img-egg" src={egg_1} alt="" />
            <Button
              variant="primary"
              className="jumbo"
              onClick={() => {
                web3 === null ? login({ showModal: true }) : handleShow();
              }}>
              Mint
            </Button>
          </Card.Body>
        </Card>
        <a
          className="btn btn-link mt-2 text-white"
          href="https://www.cryptounicorns.fun/tos.html"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}>
          Terms of Service
        </a>

        <Modal show={show} onHide={handleClose} backdrop="static" centered>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h2 className="text-center">Mint a Genesis Egg!</h2>
            <img className="img-egg py-3" src={egg_1} alt="" />
            <Container>
              <Row>
                <Col lg={{ span: 8, offset: 2 }}>
                  <InputGroup className="my-3" size="lg">
                    <FormControl
                      placeholder="Quantity"
                      aria-label="Quantity"
                      aria-describedby="btn-max"
                      type="text"
                      maxLength="1"
                      onKeyPress={(event) => handleKeyPress(event)}
                      onChange={(value) => handleChange(value)}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      value={quantity}
                    />
                    <Button
                      variant="outline-primary"
                      id="btn-max"
                      onClick={setMaxQuantity}>
                      Max
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col className="text-center">
                  {quantity && (
                    <p className="my-1">
                      {(quantity * 0.1).toFixed(1)} WETH plus ⛽️ fee
                    </p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="text-center">
                  {preApprovedMsg && (
                    <>
                      <Alert className="my-1" variant="info">
                        {preApprovedMsg}
                      </Alert>
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="text-center">
                  {mintQuantityMsg && (
                    <>
                      <Alert className="my-1" variant="warning">
                        {mintQuantityMsg}
                      </Alert>
                    </>
                  )}
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            {!confirmed && (
              <Button
                className="mb-4"
                variant="primary"
                disabled={quantity === "" || (approving && !confirmed)}
                onClick={handleApprove}>
                {!approving && !confirmed && <>Approve to spend WETH</>}
                {approving && !confirmed && (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2 mb-1"
                    />
                    Approving...
                  </>
                )}
              </Button>
            )}
            {confirmed && (
              <Button
                className="mb-4"
                variant="primary"
                disabled={quantity === "" || (confirming && !success)}
                onClick={handleConfirm}>
                {!confirming && !success && <>Confirm mint</>}
                {confirming && !success && (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2 mb-1"
                    />
                    Confirming...
                  </>
                )}
                {confirming && success && <>Confirmed</>}
              </Button>
            )}
          </Modal.Footer>
        </Modal>

        <ModalSuccess success={success} transactionHash={transactionHash} />
        <ModalError error={error} errorMessage={errorMessage} />
        <ModalPending pending={pending} />
        <ModalSoldout soldout={soldout} />
        <ModalTOS showTOS={showTOS} />
      </div>
    </>
  );
}

export default App;
