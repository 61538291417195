import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import egg_error from "../img/CU_error.jpg";

const ModalError = ({ error, errorMessage }) => {
  const [show, setShow] = useState(error);

  useEffect(() => {
    setShow(error);
  }, [error]);

  const handleClose = () => setShow(false);

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2 className="text-center">Uh Oh!</h2>
          <img className="img-egg error py-3" src={egg_error} alt="" />
          <p className="text-center my-1">{errorMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="mb-4" variant="primary" onClick={handleClose}>
            Try Again
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalError;
