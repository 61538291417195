import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

import egg_pending from "../img/CU_pending.jpg";

const ModalPending = ({ pending }) => {
  const [show, setShow] = useState(pending);

  useEffect(() => {
    setShow(pending);
  }, [pending]);

  const handleClose = () => setShow(false);

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" centered>
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body>
          <h2 className="text-center">Pending...</h2>
          <img className="img-egg pending py-3" src={egg_pending} alt="" />
          <p className="text-center mt-1 mb-0">
            Your order has been submitted. Checking for results...
          </p>
          <p className="text-center mt-2 mb-0">
            This could take several minutes.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Spinner animation="border" role="status" className="mb-4">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Footer>
        {/* <Modal.Footer>
          <Button className="mb-4" variant="primary" onClick={handleClose}>
            Continue
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default ModalPending;
